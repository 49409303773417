<!--
* @Description 行业案例-张靖皋长江大桥
* @fileName index.vue
* @MethodAuthor stjh
* @Date 2023-06-30 17:20:54
-->
<template>
  <div class="aboutContainer">
    <div class="top"></div>
    <div class="con">
      <h1 class="hyalTitle">{{ $t("crossRiverBridge.section1_title") }}</h1>
      <div class="plr60">
        <div class="conImgH545 mb40">
          <img :src="imgs[0].url" alt="" />
        </div>
      </div>
      <div class="conText mb40">
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section1_content1") }}</p>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section1_content2") }}</p>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section1_content3") }}</p>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section1_content4") }}</p>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section1_content5") }}</p>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section1_content6") }}</p>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section1_content7") }}</p>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section1_content8") }}</p>
      </div>
      <div class="conText mb24">
        <h2 class="conSubTitle">{{ $t("crossRiverBridge.section2_title") }}</h2>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section2_content1") }}</p>
      </div>

      <div class="plr60">
        <div class="conImgH420 mb40">
          <img :src="imgs[1].url" alt="" />
        </div>
      </div>
      <div class="conText mb24">
        <h2 class="conSubTitle">{{ $t("crossRiverBridge.section3_title") }}</h2>
        
        <p style="text-indent: 34px;">{{ $t("crossRiverBridge.section3_content1") }}</p>
      </div>

      <div class="plr60">
        <div class="conImgH604 mb40">
          <img :src="imgs[2].url" alt="" />
        </div>
      </div>
      <!-- <div class="conText pb24">
        <h2 class="conSubTitle">数字一体化，桥“见”未来</h2>
        <p>
          　　张靖皋长江大桥建设周期长、风险大、要求高，作为特大悬索桥建设协同管理、智能建造、智慧工地一体化平台，在保证桥梁安全、高质量的建设同时，对促进大桥建设管理数字化方面具有重要的意义。每天数千条工程建设管理数据电子化线上流转审批，基于数据共享、提取、推送的核心业务智慧化管理，经济效益显著。主要材料的正反向溯源实现工程建设质量的可追溯性，全流程、全要素的数字化管理，为进度统计、质量管控、投资控制提供准确数据，实现建设期的精细化管理，BIM协同+智慧工地+智能建造实现工程建设的可视化管理，管理效益显著。完整全面的建设期模型及数据的分层次存储，将来移交运营养护单位，为大桥的建养一体化平台提供支撑，社会效益显著，实现了交通基础设施建设管理创新，建立新一代桥梁工程智慧管理、智能建造体系。
          <br />
          未来，华设将以创新、创意为驱动，以数字化、平台化为战略导向，通过核心技术引领，加快信息技术与工程的融和。项目研发团队也将以拼搏者的精神，进一步提升华设在数字化技术领域探索的深度和广度，以科技助力、技术引领，持续为客户创造价值。
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        {
          url: require("../../../assets/images/industryImg/张靖皋1.png"),
        },
        { url: require("../../../assets/images/industryImg/张靖皋2.png") },
        { url: require("../../../assets/images/industryImg/张靖皋3.png") },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.aboutContainer {
  background: #ffffff;
  .top {
    width: 100%;
    height: 400px;
    // background: #b6bfd6;
    background-image: url("~@/assets/images/industryImg/5fc6-2c9e6c6658af68005233c6c04d31f570@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .con {
    width: 1200px;
    text-align: center;
    // padding: 0 360px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
